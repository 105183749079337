import { useEffect, useState, useCallback, useContext } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ThemeContext } from "styled-components";

import {
  SearchkitManager,
  SearchkitProvider,
  Hits,
  NoHits,
  Layout,
  LayoutBody,
  TermQuery,
  GroupedSelectedFilters,
  Pagination,
  BoolShould,
  SimpleQueryString,
  SideBar,
  RefinementListFilter,
  LayoutResults,
  ResetFilters,
  InitialLoader,
} from "searchkit";

// Icons
import CloseIcon from "@material-ui/icons/Close";

// Components
import Button from "@material-ui/core/Button";
import CircularProgressCenter from "components/CircularProgressCenter";
import ExportDialog from "features/Asset/ExportDialog";

// Elastic Search Components
import SearchKitWrapper from "components/ElasticSearch/SearchkitWrapper";
import FilterRefinementOption from "components/ElasticSearch/Filters/FilterRefinementOption";
import FilterRefinementOptionWithColor from "components/ElasticSearch/Filters/FilterRefinementOptionWithColor";
import CountryFilterOption from "components/ElasticSearch/Filters/CountryFilterOption";
import GroupedSelectedFiltersOverview from "components/ElasticSearch/Filters/GroupedSelectedFilters";
import ErrorsDisplay from "components/ElasticSearch/ErrorsDisplay";
import ResetFilterButton from "components/ElasticSearch/ResetFilterButton";
import PaginationBox from "components/ElasticSearch/PaginationBox";
import NoHitsDisplay from "components/ElasticSearch/NoHitsDisplay";

// Services
import { getSelectedSearchIds } from "features/Asset/services";

// Utils
import { usePrevious, usePersistentState } from "features/Common/hooks";

// Local Components
import AssetTable from "./AssetTable";
import AssetEdit from "../AssetEdit";
import SearchHeader from "./SearchHeader";
import MoreMenu from "./SearchHeader/MoreMenu";

// Style
import StyledComponent from "./styles";

// Constants
import { FORM_MODES } from "../AssetEdit/constants";
import { FRAGMENT_STATUS_COLORS } from "./constants";
import { setSelectedType } from "../Asset.actions";

const FILTER_TRANSLATIONS = (t) => ({
  "facets.view_more": t("labels.VIEW_MORE"),
  "facets.view_less": t("labels.VIEW_LESS"),
  "facets.view_all": t("labels.VIEW_ALL"),
});

const HOST = process.env.REACT_APP_ALLIN_ELASTIC_SEARCH;
const searchKitManager = new SearchkitManager(HOST, {
  withCredentials: true,
});

const NestedFragmentSearchQuery = (searchQuery, language) => ({
  nested: {
    path: "fragments",
    query: {
      match: {
        [`fragments.name.${language}`]: searchQuery,
      },
    },
    inner_hits: {
      highlight: {
        fields: {
          [`fragments.name.${language}`]: {},
        },
      },
    },
  },
});

const NestedLicensesSearchQuery = (searchQuery) => ({
  nested: {
    path: "licenses",
    query: {
      match: {
        "licenses.name": searchQuery,
      },
    },
  },
});

const hasSearchQuery = (queryObject) => {
  const hasQuery = queryObject.query?.bool?.should?.some((query) =>
    Object.keys(query)?.includes("simple_query_string")
  );
  return Boolean(hasQuery);
};

const addDefaultParams = (queryObject, { assetType, id }) => ({
  ...queryObject.query,

  bool: {
    filter: [TermQuery("type", assetType), TermQuery("channel.id", id)],
  },
});

// === Search Settings

const getSearchHighlightFields = (language) => [
  "identifier",
  `name.${language}`,
  "licenses.name",
];

const getSearchQueryFields = (language) => [
  "identifier",
  `name.${language}`,
  "licenses.name",
];

const getSearchPrefixQueryFields = (language) => [
  `name.${language}`,
  "identifier",
];

const getSearchSuggestionsFields = (language) => `name.${language}`;

// === Component

const AssetOverviewRoot = () => {
  const { type } = useParams();

  // Redux
  const { name: channelName, id: channelId } =
    useSelector((state) => state.channel?.data) || {};

  if (!type || !channelId || !channelName) return <CircularProgressCenter />;
  return (
    <AssetOverview
      channelId={channelId}
      type={type}
      channelName={channelName}
    />
  );
};

const AssetOverview = ({ type, channelId, channelName }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);

  // Redux
  const {
    default_country: defaultCountry,
    default_language: defaultLanguage,
  } = useSelector((state) => state.user.user);

  const selectedLocale = defaultCountry?.code || "nl";
  const selectedLanguage = defaultLanguage?.code || "nl";

  const title = type === "video" ? "titles.VIDEO" : "titles.SERIES";

  // State
  const [showFiltersPanel, setShowFiltersPanel] = usePersistentState(
    true,
    "asset.overview.filter-panel"
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [dateType, setDateType] = useState("schedule_start");
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [fetchingSelectedIds, setFetchingSelectedIds] = useState(false);

  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState("asc");
  const [queryObjectTest, setQueryObject] = useState(null);

  // Prev state
  const previousChannelId = usePrevious(channelId);
  const prevOrder = usePrevious(order);
  const prevOrderBy = usePrevious(orderBy);

  // Search Settings
  const searchQueryFields = getSearchQueryFields(selectedLanguage);
  const prefixQueryFields = getSearchPrefixQueryFields(selectedLanguage);
  const highlightFields = getSearchHighlightFields(selectedLanguage);
  const suggestionsField = getSearchSuggestionsFields(selectedLanguage);

  // Searchkit Manager
  useEffect(() => {
    searchKitManager.setQueryProcessor((plainQueryObject) => {
      const queryObject = { ...plainQueryObject };
      const hasQuery = hasSearchQuery(queryObject);

      // Add default params
      queryObject.query = addDefaultParams(plainQueryObject, {
        assetType: type,
        id: channelId,
      });

      // Add dynamic search query
      if (plainQueryObject.query?.bool?.should) {
        queryObject.query.bool.must = [
          ...(queryObject.query.bool.must || []),
          {
            bool: {
              should: plainQueryObject.query?.bool?.should,
            },
          },
        ];
      }

      // TODO: move to functions like addDefaultParams
      // If no query
      if (!hasQuery) {
        queryObject.query = {
          function_score: {
            boost_mode: "sum",
            functions: [
              {
                filter: {
                  exists: {
                    field: `schedule.${selectedLocale}.schedule_start`,
                  },
                },
                weight: 5,
              },
              {
                filter: {
                  term: {
                    quality_check: true,
                  },
                },
                weight: 3,
              },
              {
                filter: {
                  bool: {
                    must_not: {
                      match: {
                        completed: selectedLocale,
                      },
                    },
                  },
                },
                weight: 2,
              },
            ],
            query: queryObject.query,
          },
        };

        if (!orderBy) {
          queryObject.sort = [
            {
              _score: { order: "desc" },
            },
            {
              [`schedule.${selectedLocale}.schedule_start`]: { order: "asc" },
            },
            {
              [`schedule.${selectedLocale}.contract_start`]: { order: "asc" },
            },
            {
              "licenses.name.raw": {
                order: "asc",
                nested: {
                  path: "licenses",
                },
              },
            },
          ];
        } else {
          queryObject.sort = [{ [orderBy]: { order } }];
        }
      }

      // TODO: fix this workaround
      setQueryObject(queryObject);

      return queryObject;
    });

    return () => {
      searchKitManager.resetState();
    };
  }, [type, channelId, order, orderBy, selectedLocale]);

  // Reload search on sort change
  useEffect(() => {
    if (order && orderBy && (order !== prevOrder || prevOrderBy !== orderBy)) {
      searchKitManager.reloadSearch();
    }
  }, [order, prevOrder, orderBy, prevOrderBy]);

  // Reload search on channel change
  useEffect(() => {
    if (
      searchKitManager &&
      channelId &&
      previousChannelId &&
      channelId !== previousChannelId
    ) {
      searchKitManager.resetState();
      searchKitManager.reloadSearch();
    }
    return () => {
      setSelectedRows([]);
    };
  }, [channelId, previousChannelId]);

  // SetSelected Asset
  useEffect(() => {
    dispatch(setSelectedType(type));
  }, [type, dispatch]);

  // Listeners

  const onRowSelect = useCallback(
    (event, index) => {
      const selectedIndex = selectedRows.indexOf(index);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedRows, index);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRows.slice(1));
      } else if (selectedIndex === selectedRows.length - 1) {
        newSelected = newSelected.concat(selectedRows.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedRows.slice(0, selectedIndex),
          selectedRows.slice(selectedIndex + 1)
        );
      }

      setSelectedRows(newSelected);
    },
    [selectedRows, setSelectedRows]
  );

  // Listeners
  const onToggleFiltersPanel = () => {
    setShowFiltersPanel(!showFiltersPanel);
  };

  const onDateTypeChange = (contractDateType) => {
    setDateType(contractDateType);
  };

  const onOpenCreateDialog = () => {
    setCreateDialogOpen(true);
  };

  const onSort = useCallback(
    ({ property }) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [setOrderBy, setOrder, orderBy, order]
  );

  const onRowClick = useCallback(
    ({ assetId, fragmentId }) => {
      let path = `/channel/${channelId}/${type}/${assetId}`;
      if (fragmentId) {
        path = `${path}/fragment/${fragmentId}`;
      }
      history.push(path);
    },
    [history, channelId, type]
  );

  const deselectAll = (closeMenu) => {
    setSelectedRows([]);
    closeMenu();
  };

  const onExportClick = (closeMenu) => {
    setExportDialogOpen(true);
    closeMenu();
  };

  const onExportDialogClose = () => {
    setExportDialogOpen(false);
  };

  const searchBoxQueryBuilder = (query, options) =>
    BoolShould([
      // Use custom nested search query for fragment inner hits.
      NestedFragmentSearchQuery(query, selectedLanguage),
      NestedLicensesSearchQuery(query),
      SimpleQueryString(query, { fields: options.fields }),
    ]);

  const handleSelectAllClick = async (event) => {
    if (event.target.checked) {
      try {
        setFetchingSelectedIds(true);
        const selectedSearchIds = await getSelectedSearchIds({
          queryObject: queryObjectTest,
        });
        setSelectedRows(selectedSearchIds);
      } catch (error) {
        // stub
      } finally {
        setFetchingSelectedIds(false);
      }
    } else {
      setSelectedRows([]);
    }
  };

  if (!searchKitManager) return <CircularProgressCenter />;
  return (
    <SearchkitProvider searchkit={searchKitManager}>
      <SearchKitWrapper>
        {({ loading, filterCount, totalCount }) => (
          <StyledComponent>
            <Helmet>
              <title>
                {t("titles.APP", { name: process.env.REACT_APP_TITLE })} -{" "}
                {channelName || ""} -{t(`${title}`)}
              </title>
            </Helmet>
            <Layout>
              <SearchHeader
                searchQueryFields={searchQueryFields}
                prefixQueryFields={prefixQueryFields}
                queryBuilder={searchBoxQueryBuilder}
                onToggleFilters={onToggleFiltersPanel}
                showFiltersPanel={showFiltersPanel}
                locale={selectedLocale}
                selected={selectedRows}
                filterCount={filterCount}
                searching={loading}
                onOpenCreateDialog={onOpenCreateDialog}
                renderMenuItems={({ closeMenu }) => (
                  <MoreMenu
                    onExportClick={onExportClick}
                    deselectAll={deselectAll}
                    closeMenu={closeMenu}
                  />
                )}
              />
              <LayoutBody id="layout-body">
                {loading && <CircularProgressCenter />}
                <LayoutResults>
                  <InitialLoader
                    component={() => (
                      <CircularProgressCenter variant="inline" />
                    )}
                  />
                  <Hits
                    highlightFields={highlightFields}
                    scrollTo="body"
                    listComponent={(props) => (
                      <AssetTable
                        {...props}
                        type={type}
                        locale={selectedLocale}
                        language={selectedLanguage}
                        selectedRows={selectedRows}
                        order={order}
                        orderBy={orderBy}
                        dateType={dateType}
                        onDateTypeChange={onDateTypeChange}
                        onRowSelect={onRowSelect}
                        onRowClick={onRowClick}
                        onSort={onSort}
                        totalCount={totalCount}
                        onSelectAllClick={handleSelectAllClick}
                        fetchingSelectedIds={fetchingSelectedIds}
                      />
                    )}
                  />
                  <NoHits
                    component={NoHitsDisplay}
                    errorComponent={ErrorsDisplay}
                    translations={{
                      "NoHits.NoResultsFound": t("text.NO_HITS_NO_RESULTS"),
                      "NoHits.DidYouMean": t("text.NO_HITS_NO_RESULTS"),
                      "NoHits.SearchWithoutFilters": t(
                        "text.NO_HITS_SEARCH_WITHOUT_FILTERS"
                      ),
                    }}
                    suggestionsField={suggestionsField}
                  />
                  <Pagination showNumbers listComponent={PaginationBox} />
                </LayoutResults>

                <SideBar className={showFiltersPanel ? "active" : "inactive"}>
                  <header>
                    <Button
                      variant="outlined"
                      color="default"
                      size="small"
                      onClick={onToggleFiltersPanel}
                      endIcon={<CloseIcon />}
                    >
                      {t("labels.CLOSE")}
                    </Button>
                  </header>
                  <GroupedSelectedFilters
                    groupComponent={GroupedSelectedFiltersOverview}
                  />
                  <ResetFilters component={ResetFilterButton} />

                  <RefinementListFilter
                    id="countries"
                    title={t("labels.COUNTRIES")}
                    field="countries"
                    operator="OR"
                    translations={FILTER_TRANSLATIONS(t)}
                    itemComponent={(props) => (
                      <CountryFilterOption
                        trackName="asset.overview.filter"
                        trackDetails={{
                          filter: "countries",
                          label: props.label,
                        }}
                        {...props}
                      />
                    )}
                  />
                  <RefinementListFilter
                    id="licenses"
                    title={t("labels.LICENSES")}
                    field="licenses.name.raw"
                    operator="OR"
                    itemComponent={(props) => (
                      <FilterRefinementOption
                        trackName="asset.overview.filter"
                        trackDetails={{
                          filter: "licenses",
                          label: props.label,
                        }}
                        {...props}
                      />
                    )}
                    size={4}
                    fieldOptions={{
                      type: "nested",
                      options: {
                        path: "licenses",
                      },
                    }}
                    translations={FILTER_TRANSLATIONS(t)}
                  />
                  <RefinementListFilter
                    id="contracts"
                    title={t("labels.CONTRACTS")}
                    field="contracts.name.raw"
                    operator="OR"
                    size={4}
                    translations={FILTER_TRANSLATIONS(t)}
                    itemComponent={(props) => (
                      <FilterRefinementOption
                        trackName="asset.overview.filter"
                        trackDetails={{
                          filter: "contracts",
                          label: props.label,
                        }}
                        {...props}
                      />
                    )}
                    fieldOptions={{
                      type: "nested",
                      options: {
                        path: "contracts",
                      },
                    }}
                  />
                  <RefinementListFilter
                    id="subtitle-status"
                    title={t("labels.SUBTITLE_STATUS")}
                    field="fragments.files.status"
                    operator="OR"
                    size={4}
                    translations={FILTER_TRANSLATIONS(t)}
                    itemComponent={(props) => (
                      <FilterRefinementOptionWithColor
                        trackName="asset.overview.filter"
                        trackDetails={{
                          filter: "subtitle-status",
                          label: props.label,
                        }}
                        colors={theme.palette.status}
                        {...props}
                      />
                    )}
                    fieldOptions={{
                      type: "nested",
                      options: {
                        path: "fragments.files",
                      },
                    }}
                  />

                  <RefinementListFilter
                    id="fragment-status"
                    title={t("labels.FRAGMENT_STATUS")}
                    field="fragments.state.name"
                    operator="OR"
                    size={4}
                    translations={FILTER_TRANSLATIONS(t)}
                    itemComponent={(props) => (
                      <FilterRefinementOptionWithColor
                        trackName="asset.overview.filter"
                        trackDetails={{
                          filter: "fragment-status",
                          label: props.label,
                        }}
                        colors={FRAGMENT_STATUS_COLORS}
                        {...props}
                      />
                    )}
                    fieldOptions={{
                      type: "nested",
                      options: {
                        path: "fragments",
                      },
                    }}
                  />

                  <RefinementListFilter
                    id="genres"
                    title={t("labels.GENRES")}
                    field="fragments.genres"
                    operator="OR"
                    size={4}
                    translations={FILTER_TRANSLATIONS(t)}
                    itemComponent={(props) => (
                      <FilterRefinementOption
                        trackName="asset.overview.filter"
                        trackDetails={{
                          filter: "genres",
                          label: props.label,
                        }}
                        {...props}
                      />
                    )}
                    fieldOptions={{
                      type: "nested",
                      options: {
                        path: "fragments",
                      },
                    }}
                  />
                  <RefinementListFilter
                    id="tags"
                    title={t("labels.TAGS")}
                    field="fragments.tags"
                    operator="OR"
                    size={4}
                    translations={FILTER_TRANSLATIONS(t)}
                    itemComponent={(props) => (
                      <FilterRefinementOption
                        trackName="asset.overview.filter"
                        trackDetails={{
                          filter: "tags",
                          label: props.label,
                        }}
                        {...props}
                      />
                    )}
                    fieldOptions={{
                      type: "nested",
                      options: {
                        path: "fragments",
                      },
                    }}
                  />
                </SideBar>
                <ExportDialog
                  title={t("labels.EXPORT")}
                  type={type}
                  open={exportDialogOpen}
                  selectedRows={selectedRows}
                  onClose={onExportDialogClose}
                />
              </LayoutBody>
            </Layout>
            <AssetEdit
              type={type}
              open={createDialogOpen}
              onClose={() => setCreateDialogOpen(false)}
              mode={FORM_MODES.new}
            />
          </StyledComponent>
        )}
      </SearchKitWrapper>
    </SearchkitProvider>
  );
};

export default AssetOverviewRoot;
