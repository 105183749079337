/* eslint-disable prefer-spread */
import { useEffect, useState, useMemo, useCallback, memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

// Utils
import Utils from "features/Common/utils";

// Components
import { Checkbox } from "@material-ui/core";
import { AutoCompleteWithControl } from "components/Form";

// Component
const AssetEditFormRight = () => {
  const { control, errors, setValue, watch } = useFormContext();
  const { t } = useTranslation();

  const channel = useSelector((state) => state.channel?.data) || {};
  const [contractOptions, setContractOptions] = useState([]);

  const { licenses } = channel;

  const formLicenses = watch("licenses");
  const formContracts = watch("contracts");

  const selectedLicenses = useMemo(() => [...(formLicenses || [])], [
    formLicenses,
  ]);

  const channelLicenses = useMemo(
    () =>
      Utils.Common.normalize([...(licenses || [])], (license) => license.id),
    [licenses]
  );

  const getSelectedLicenseContracts = useCallback(
    (allLicenses) => {
      const selectedLicensesIds = allLicenses?.map((li) => li.id) || [];
      const options =
        selectedLicensesIds?.reduce(
          (result, id) => [...result, ...channelLicenses[id]?.contracts],
          []
        ) || [];

      return options;
    },
    [channelLicenses]
  );

  const updateContracts = (allLicenses) => {
    const options = getSelectedLicenseContracts(allLicenses);

    // Filter out deselected licenses
    const selectedContracts = formContracts.filter((formContract) => {
      const hasItem =
        options.findIndex(
          (contractOption) => contractOption.id === formContract.id
        ) > -1;
      return hasItem;
    });

    setValue("contracts", selectedContracts);
  };

  // Show available contract options
  useEffect(() => {
    const newContracts = getSelectedLicenseContracts(selectedLicenses);
    setContractOptions(newContracts);
  }, [channelLicenses, selectedLicenses, getSelectedLicenseContracts]);

  return (
    <>
      <AutoCompleteWithControl
        name="licenses"
        multiple
        className="inputMargin"
        label={t("labels.LICENSE")}
        variant="outlined"
        fullWidth
        options={channel.licenses || []}
        control={control}
        onChange={updateContracts}
        error={errors.licenses?.message}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option.id === value.id}
        renderOption={(option, { selected }) => (
          <>
            <Checkbox
              color="primary"
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </>
        )}
      />

      <AutoCompleteWithControl
        name="contracts"
        multiple
        className="inputMargin"
        label={t("labels.CONTRACTS")}
        variant="outlined"
        fullWidth
        options={contractOptions || []}
        control={control}
        error={errors.contracts?.message}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        renderOption={(option, { selected }) => (
          <>
            <Checkbox
              color="primary"
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </>
        )}
      />
    </>
  );
};

export default memo(AssetEditFormRight);
