import { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

// Icons
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import SettingIcon from "@material-ui/icons/Settings";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import CheckIcon from "@material-ui/icons/Check";
import AssignmentIcon from "@material-ui/icons/Assignment";

// Components
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import JobListContainer from "../Jobs";
import Licenses from "../Licenses";

// Style
import styles from "./List.styles";
import CollectionContainer from "../Collections/CollectionContainer";
import RedirectOldAllin from "./RedirectOldAllin";

class ListContainer extends Component {
  constructor(props) {
    super(props);

    this.menu = [
      {
        name: "titles.JOB",
        setting: "job",
        icon: PlaylistAddCheckIcon,
        component: JobListContainer,
      },
      {
        name: "titles.LICENSES",
        setting: "licenses",
        icon: PermContactCalendarIcon,
        component: Licenses,
      },
      {
        name: "titles.COLLECTION",
        setting: "collection",
        icon: CollectionsBookmarkIcon,
        component: CollectionContainer,
      },
      {
        name: "titles.CONFIGURATION",
        setting: "config",
        icon: SettingIcon,
        component: RedirectOldAllin,
      },
      {
        name: "titles.FORMS",
        setting: "form",
        icon: LibraryBooksIcon,
        component: RedirectOldAllin,
      },
      {
        name: "titles.VIDEO_PRESET",
        setting: "preset",
        icon: VideoLibraryIcon,
        component: RedirectOldAllin,
      },
      {
        name: "titles.ASSET_QUALITY_CHECK",
        setting: "check",
        icon: CheckIcon,
        component: RedirectOldAllin,
      },
      {
        name: "titles.CONTRACTS",
        setting: "contract",
        icon: AssignmentIcon,
        component: RedirectOldAllin,
      },
    ];
  }

  renderMenu(selectedSetting) {
    const { classes, t, channel } = this.props;

    return (
      <List className={classes.menuList}>
        {this.menu.map((menuItem) => (
          <Link
            key={menuItem.setting}
            to={`/channel/${channel.selected}/settings/${menuItem.setting}`}
            className={classes.link}
          >
            <ListItem
              button
              className={
                selectedSetting && selectedSetting.setting === menuItem.setting
                  ? classes.settingSelected
                  : null
              }
            >
              <Tooltip title={t(menuItem.name)}>
                <menuItem.icon />
              </Tooltip>
            </ListItem>
          </Link>
        ))}
      </List>
    );
  }

  render() {
    const { t, channel, match, history } = this.props;
    const { classes, ...renderProps } = this.props;

    if (!channel.selected) {
      return null;
    }

    const setting = this.menu.find(
      (item) => item.setting === match.params.setting
    );

    if (!setting) {
      history.replace(
        `/channel/${channel.selected}/settings/${this.menu[0].setting}`
      );
    }

    return (
      <div className={classes.root}>
        <Helmet>
          <title>
            {t("titles.APP", { name: process.env.REACT_APP_TITLE })} -{" "}
            {channel.data ? channel.data.name : ""} -{t("titles.APP_SETTINGS")}
            {setting ? `- ${t(setting.name)}` : ""}
          </title>
        </Helmet>
        <Grid container>
          <Grid container className={classes.menuRoot}>
            {this.renderMenu(setting)}
          </Grid>

          {setting && (
            <Grid container className={classes.settingContent}>
              <setting.component {...renderProps} />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

ListContainer.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  channel: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  channel: state.channel,
});

export default compose(
  withStyles(styles),
  withTranslation(),
  connect(mapStateToProps)
)(ListContainer);
