/* eslint-disable camelcase */
import { useEffect, useState, useRef, useMemo, useCallback, memo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Alert from "@material-ui/lab/Alert";

// UI
import { Container, Grid, Box, Button } from "@material-ui/core";

// Icons
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CachedIcon from "@material-ui/icons/Cached";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import PublishIcon from "@material-ui/icons/Publish";
import PostAddIcon from "@material-ui/icons/PostAdd";

// Hooks
import { getCurrentLanguageCode } from "features/Common/selectors";
import useIndexableButton from "features/Fragment/FragmentDetail/useIndexableButton";
import useStateButton from "features/Fragment/FragmentDetail/useStateButton";

// Utils
import Utils from "features/Common/utils";

// Component
import CircularProgressCenter from "components/CircularProgressCenter";
import VideoPlayer from "components/VideoPlayer";

// Local Components
import VideoDetails from "../FragmentDetailVideoDetails";
import FragmentInformation from "../FragmentDetailInformation";
import FragmentImages from "../FragmentDetailImages";
import FragmentSubtitles from "../FragmentDetailSubtitles";
import FragmentMeta from "../FragmentDetailMeta";
import FragmentLabels from "../FragmentDetailLabels";
import FragmentVideoEditor from "../FragmentDetailVideoEditor";
import FragmentIngestDialog from "../FragmentIngestDialog";
import FragmentClearCache from "../FragmentClearCache";
import FragmentDeleteDialog from "../FragmentDelete";
import FragmentArchiveDialog from "../FragmentArchive";
import SubtitleViewer from "../FragmentDetailSubtitleViewer";
import StateButton from "./StateButton";
import IndexableButton from "./IndexableButton";

// Redux
import {
  loadFragment,
  resetFragment,
  updateVideoCuePoints,
  patchFragment,
  patchFragmentSuccess,
  updateSource,
} from "../reducer";
import { getImageTypes } from "../../File/selectors";
import { getCurrentFragment } from "../selectors";
import { getCurrentChannel } from "../../Channel/selectors";
import { getCurrentAsset } from "../../Asset/selectors";
import { findById } from "features/Asset/Asset.actions";

// Style
import StyledComponent from "./style";

import { useCurrentFragmentUrl } from "../hooks";
import { unArchiveFragment } from "../services";
import { useJob } from "../FragmentJobs/JobProvider";
import FragmentSocialPost from "../FragmentSocialPost";

const VIDEO_DETAILS_MENU_ITEMS = [
  {
    key: "tasks",
    title: "menuItems.TASK",
    icon: <AssignmentTurnedInIcon fontSize="small" />,
    can: {
      subject: "job",
      actions: "view",
    },
  },
  {
    key: "ingest",
    title: "menuItems.INGEST",
    icon: <CloudUploadIcon fontSize="small" />,
  },
  {
    key: "axinom-upload",
    title: "menuItems.AXINOM_UPLOAD",
    icon: <PublishIcon fontSize="small" />,
  },
  {
    key: "clear-cache",
    title: "menuItems.CLEAR_CACHE",
    icon: <CachedIcon fontSize="small" />,
    can: {
      subject: "asset-fragment",
      actions: "purge",
    },
  },
  {
    key: "archive",
    title: "menuItems.ARCHIVE",
    can: {
      subject: "asset-fragment",
      actions: "delete",
    },
  },
  {
    key: "social-posts",
    title: "menuItems.SOCIAL_POSTS",
    icon: <PostAddIcon fontSize="small" />,
  },
  {
    key: "delete-fragment",
    title: "menuItems.DELETE_FRAGMENT",
    icon: <DeleteForeverIcon fontSize="small" />,
    can: {
      subject: "asset-fragment",
      actions: "delete",
    },
  },
];

const getVideoSource = (fileHash) => ({
  src: Utils.Fragments.getAssetVideoPath(fileHash),
  type: "video/mp4",
});

const FragmentDetail = () => {
  const dispatch = useDispatch();
  const videoRef = useRef();
  const { t } = useTranslation();
  const { fragmentId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { startJob } = useJob();

  const [videoEditorPanel, setVideoEditorPanel] = useState(null);

  const [videoError, setVideoError] = useState(null);
  const [videoEditorIsDragging, setVideoEditorIsDragging] = useState(false);
  const [videoCurrentTimeMs, setVideoCurrentTimeMs] = useState(0);
  const [videoEditorActive, setVideoEditorActive] = useState(null);
  const [videoLanguage, setVideoLanguage] = useState();

  const [fragmentDeleteDialogOpen, setDeleteFragmentDialogOpen] =
    useState(false);
  const [fragmentSocialPostDialogOpen, setFragmentSocialPostDialogOpen] =
    useState(false);
  const [fragmentIngestDialogOpen, setFragmentIngestDialogOpen] =
    useState(false);
  const [fragmentClearCacheDialogOpen, setFragmentClearCacheDialogOpen] =
    useState(false);
  const [fragmentAchriveDialogOpen, setFragmentAchriveDialogOpen] =
    useState(false);

  const [videoMetaLoading, setVideoMetaLoading] = useState(true);
  const [videoControlsVisible, setVideoControlsVisible] = useState(true);
  const [videoDurationMs, setVideoDurationMs] = useState(0);
  const [seekTimeInfo, setSeekTimeInfo] = useState({
    key: "1/25",
    value: 1 / 25,
  });
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { data: channel } = useSelector(getCurrentChannel);
  const { data: asset } = useSelector(getCurrentAsset);
  const {
    data: fragment,
    files: fragmentFiles,
    loading,
  } = useSelector(getCurrentFragment);
  const videoInformationState = useSelector(
    (state) => state.fragment.networkState?.videoInformation
  );

  const currentLanguageCode = useSelector(getCurrentLanguageCode);
  const imageTypes = useSelector(getImageTypes);
  const fragmentFileHash = fragment?.file?.hash;

  // Hooks
  const [indexable, onIndexableClick] = useIndexableButton({ fragment });
  const [state, fragmentStates, onStateChange] = useStateButton({
    fragment,
    channel,
  });

  const fadeIn = fragment?.fade_in || 0;
  const introIn = fragment?.intro_in || 0;
  const introOut = fragment?.intro_out || 0;

  /* Meta data form fields */

  const openInformationSidebar = useCallback(() => {
    setSidebarOpen(!sidebarOpen);
  }, [setSidebarOpen, sidebarOpen]);

  const openVideoEditor = useCallback(
    (active) => {
      setVideoEditorPanel("videoEditor");
      setVideoEditorActive(active);
    },
    [setVideoEditorPanel, setVideoEditorActive]
  );

  const onVideoDetailsEditClick = useCallback(() => {
    openVideoEditor(null);
  }, [openVideoEditor]);

  const onOpenSubtitleViewerClick = useCallback(
    (language) => {
      setVideoEditorPanel("subtitleViewer");
      if (!videoRef?.current) return;
      videoRef.current.setActiveLanguage(language);
    },
    [videoRef, setVideoEditorPanel]
  );

  /* Common callbacks */

  const onSkipIntroClick = () => {
    videoRef.current.setCurrentTime(introOut / 1000);
    setVideoEditorActive(null);
  };

  const onCuePointChanged = useCallback(
    (changes) => {
      dispatch(updateVideoCuePoints(changes));
    },
    [dispatch]
  );

  // Keep seekTime in sync
  useEffect(() => {
    if (!videoRef?.current) return;
    const { value } = seekTimeInfo;
    videoRef.current.setSeekTime(value);
  }, [setSeekTimeInfo, seekTimeInfo, videoRef]);

  const url = useCurrentFragmentUrl({ fragmentId });
  const panelUrl = `${url}?panel=jobs`;
  const onActionMenuClick = useCallback(
    async (key) => {
      switch (key) {
        case "tasks":
          history.push(panelUrl);
          break;

        case "ingest":
          setFragmentIngestDialogOpen(true);
          break;

        case "clear-cache":
          setFragmentClearCacheDialogOpen(true);
          break;

        case "delete-fragment":
          setDeleteFragmentDialogOpen(true);
          break;

        case "social-posts":
          setFragmentSocialPostDialogOpen(true);
          break;

        case "archive":
          setFragmentAchriveDialogOpen(true);
          break;

        case "axinom-upload":
          const preset = channel.presets.find((p) => p.type === "axinom");

          if (preset) {
            startJob(5, preset?.id);
          } else {
            enqueueSnackbar("Preset not found", {
              variant: "error",
            });
          }

          break;

        case "unarchive":
          const response = await unArchiveFragment(fragmentId);
          dispatch(patchFragmentSuccess(response.data));
          dispatch(findById(fragment.asset.id));
          break;

        default:
          // eslint-disable-next-line no-alert
          alert(`Action not implemented yet ${key}`);
          break;
      }
    },
    [
      history,
      panelUrl,
      channel?.presets,
      fragmentId,
      dispatch,
      fragment?.asset?.id,
      startJob,
      enqueueSnackbar,
    ]
  );

  /* === Video player callbacks == */

  const updateTime = useCallback(() => {
    setVideoCurrentTimeMs(videoRef?.current?.getCurrentTime() * 1000);
  }, []);

  const onTimeUpdate = useCallback(() => {
    updateTime();
  }, [updateTime]);

  const onSeeking = useCallback(() => {
    updateTime();
  }, [updateTime]);

  const onCanPlay = useCallback(() => {
    setVideoError(null);
  }, [setVideoError]);

  const onLoadedMetaData = useCallback(
    ({ duration }) => {
      const durationMs = duration * 1000;
      setVideoDurationMs(durationMs);
      setVideoMetaLoading(false);
      setVideoLanguage(videoRef?.current?.getLanguage());
    },
    [setVideoDurationMs, setVideoMetaLoading, videoRef]
  );

  const onSeeked = useCallback(() => {
    if (videoEditorIsDragging) return;
    updateTime();
  }, [updateTime, videoEditorIsDragging]);

  const onControlsShown = useCallback(() => {
    setVideoControlsVisible(true);
  }, [setVideoControlsVisible]);

  const onControlsHidden = useCallback(() => {
    setVideoControlsVisible(false);
  }, [setVideoControlsVisible]);

  const onVideoError = useCallback(
    (error) => {
      setVideoMetaLoading(false);
      setVideoError(error);
    },
    [setVideoError]
  );

  const onLanguageChange = useCallback(
    ({ language }) => {
      if (language === videoLanguage) return;
      setVideoLanguage(language);
    },
    [setVideoLanguage, videoLanguage]
  );

  /* == Video Editor callbacks == */

  const onVideoEditorTimeChange = useCallback(
    (changes) => {
      // This will decrease performance
      if (!videoRef?.current) return;
      if (!videoEditorIsDragging) {
        setVideoEditorIsDragging(true);
      }

      videoRef.current.setCurrentTime(changes.value / 1000);
      setVideoEditorActive(changes.type);
    },
    [videoEditorIsDragging, setVideoEditorIsDragging]
  );

  const onVideoEditorTimeCommitted = useCallback(
    (changes) => {
      if (!videoRef?.current) return;
      videoRef.current.setCurrentTime(changes.value / 1000);

      setVideoEditorActive(changes.type);
      onCuePointChanged({ ...changes, videoDuration: videoDurationMs });
      setVideoEditorIsDragging(false);
    },
    [onCuePointChanged, setVideoEditorIsDragging, videoDurationMs]
  );

  const onUseCurrentTime = useCallback(
    (event) => {
      if (!videoRef?.current) return;

      const activeName = event.currentTarget?.name;
      setVideoEditorActive(activeName);

      onCuePointChanged({
        type: activeName,
        value: videoRef.current.getCurrentTime() * 1000,
      });
    },
    [onCuePointChanged]
  );

  const onSeekSpeedChanged = useCallback((seekInfo) => {
    setSeekTimeInfo(seekInfo);
  }, []);

  const onVideoCuePointsSave = useCallback(() => {
    const fields = {
      fade_in: fragment.fade_in || 0,
      fade_out: fragment.fade_out || videoDurationMs,
      intro_in: fragment.intro_in,
      intro_out: fragment.intro_out,
      credits_in: Math.round(fragment.credits_in),
      snapshot_time: fragment.snapshot_time,
      file: fragment.file,
      drm: Number(fragment.drm),
      trailer: Number(fragment.trailer),
      downloadable: Number(fragment.downloadable),

      _method: "PATCH",
    };

    dispatch(
      patchFragment({
        id: fragment?.id,
        fields,
        onComplete: () => {
          enqueueSnackbar(t("text.SAVED"), {
            variant: "success",
          });
        },
      })
    );
  }, [fragment, dispatch, videoDurationMs, enqueueSnackbar, t]);

  const onSourceChange = useCallback(
    (source) => {
      setVideoError(null);
      dispatch(updateSource(source));
    },
    [dispatch]
  );

  const closeVideoEditor = useCallback(() => {
    setVideoEditorPanel(null);
    setVideoEditorActive(null);
  }, [setVideoEditorPanel]);

  const onVideoEditorSettingChange = (name, value) => {
    onCuePointChanged({
      type: name,
      value,
    });
  };

  /* == Subtitle Viewer == */

  const onSubtitleTimeChanged = useCallback(
    ({ timeStamp }) => {
      if (!videoRef?.current) return;
      videoRef.current.setCurrentTime(timeStamp / 1000);
    },
    [videoRef]
  );

  const onSubtitleChange = useCallback(
    ({ subtitle }) => {
      if (!videoRef?.current) return;
      videoRef.current.setActiveLanguage(subtitle.locale);
    },
    [videoRef]
  );

  const introButtonVisible = useMemo(() => {
    const currentGreaterThenSkipStart = videoCurrentTimeMs >= introIn;
    const currentSmallerThenSkipEnd = videoCurrentTimeMs <= introOut;
    const skipStartEndOnSameFrame = introIn === introOut;

    return (
      currentGreaterThenSkipStart &&
      currentSmallerThenSkipEnd &&
      !skipStartEndOnSameFrame
    );
  }, [videoCurrentTimeMs, introIn, introOut]);

  /* Setup */

  // Initial fragment load
  useEffect(() => {
    if (!fragmentId) {
      return;
    }

    // Clear state
    setVideoError(null);
    setVideoEditorActive(null);
    closeVideoEditor();

    dispatch(
      loadFragment({ fragmentId, status: ["active", "draft", "pending"] })
    );
  }, [
    fragmentId,
    currentLanguageCode,
    dispatch,
    setVideoError,
    setVideoEditorActive,
    closeVideoEditor,
  ]);

  // Set video source and tracks
  useEffect(() => {
    if (!videoRef?.current || !fragmentFileHash) {
      return;
    }

    setVideoMetaLoading(true);
    videoRef.current.setSource(getVideoSource(fragmentFileHash));
    videoRef.current.setTracks(
      Utils.Fragments.getSubtitleTracks(fragmentFiles)
    );
  }, [fragmentFileHash, fragmentFiles, videoRef, setVideoMetaLoading]);

  // Set initial video time to fadeIn
  useEffect(() => {
    if (!videoRef || videoMetaLoading) return;
    videoRef.current.setCurrentTime(fadeIn / 1000);
  }, [videoMetaLoading, videoRef, fadeIn]);

  // Unmount and clean up
  useEffect(
    () => () => {
      dispatch(resetFragment());
    },
    [dispatch]
  );

  if (!fragmentId || !channel) {
    return null;
  }

  if (fragmentId && loading) {
    return <CircularProgressCenter type="inline" />;
  }

  if (!fragmentId || !channel || !fragment) {
    return null;
  }

  return (
    <StyledComponent>
      <Helmet>
        <title>
          {t("titles.APP", { name: process.env.REACT_APP_TITLE })} -{" "}
          {channel?.name || ""} -{t("labels.ASSET")} -{asset?.name || ""} -{" "}
          {t("labels.FRAGMENT")} -{fragment?.name || ""}
        </title>
      </Helmet>

      <Container maxWidth={false}>
        <Grid container spacing={3} className="video-container">
          <Grid item md={12} style={{ position: "relative" }}>
            <VideoPlayer
              ref={videoRef}
              onTimeUpdate={onTimeUpdate}
              onSeeking={onSeeking}
              onCanPlay={onCanPlay}
              onSeeked={onSeeked}
              onLoadedMetaData={onLoadedMetaData}
              onControlsHidden={onControlsHidden}
              onControlsShown={onControlsShown}
              onLanguageChange={onLanguageChange}
              onError={onVideoError}
            />
            {introButtonVisible && (
              <Button
                className="skip-button"
                type="button"
                style={{ bottom: videoControlsVisible ? "60px" : "24px" }}
                onClick={onSkipIntroClick}
              >
                Skip intro
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid item md={12}>
          {fragment?.archived && (
            <Alert severity="warning" style={{ marginTop: 22 }}>
              {t("labels.ARCHIVED")}
            </Alert>
          )}
          {videoEditorPanel === "videoEditor" && (
            <FragmentVideoEditor
              active={videoEditorActive}
              fragment={fragment}
              videoFiles={asset?.files}
              videoDurationMs={videoDurationMs}
              currentTimeMs={videoCurrentTimeMs}
              seekTimeInfo={seekTimeInfo}
              videoMetaLoading={videoMetaLoading}
              error={videoError}
              updating={videoInformationState === "updating"}
              onChange={onVideoEditorTimeChange}
              onUseCurrentTime={onUseCurrentTime}
              onChangeCommitted={onVideoEditorTimeCommitted}
              onSeekTimeChanged={onSeekSpeedChanged}
              onSave={onVideoCuePointsSave}
              onCancel={closeVideoEditor}
              onSettingChange={onVideoEditorSettingChange}
              onSourceChange={onSourceChange}
            />
          )}
          {videoEditorPanel === "subtitleViewer" && (
            <SubtitleViewer
              fragmentFiles={fragmentFiles}
              onTimeStampChange={onSubtitleTimeChanged}
              videoMetaLoading={videoMetaLoading}
              videoError={videoError}
              onSubtitleChange={onSubtitleChange}
              currentTimeMs={videoCurrentTimeMs}
              currentLanguage={videoLanguage}
              onClose={() => {
                setVideoEditorPanel(null);
              }}
            />
          )}
          <VideoDetails
            fragment={fragment}
            videoDurationMs={videoDurationMs}
            fragmentFiles={fragmentFiles}
            menuItems={VIDEO_DETAILS_MENU_ITEMS}
            onMenuItemClick={onActionMenuClick}
            onOpenSubtitleViewerClick={onOpenSubtitleViewerClick}
            active={videoEditorActive}
            onEditCuePointsClick={openVideoEditor}
            onEditClick={onVideoDetailsEditClick}
            activeLocale={videoLanguage}
          />
        </Grid>

        <Box m={3} />

        <Grid container spacing={3}>
          <Grid item md={12} className="action-buttons">
            <StateButton
              state={state}
              states={fragmentStates}
              onChange={onStateChange}
            />
            {!fragment?.trailer && (
              <IndexableButton
                indexable={indexable}
                onClick={onIndexableClick}
              />
            )}
          </Grid>
          <Grid item md={12}>
            <FragmentInformation
              fragment={fragment}
              action={openInformationSidebar}
            />
          </Grid>
          {!fragment?.trailer && (
            <Grid item md={12}>
              <FragmentImages
                fragment={fragment}
                fragmentFiles={fragmentFiles}
                imageTypes={imageTypes}
              />
            </Grid>
          )}
          <Grid item md={12}>
            <FragmentSubtitles
              fragment={fragment}
              fragmentFiles={fragmentFiles}
            />
          </Grid>
          <Grid item md={12}>
            <FragmentLabels fragment={fragment} />
          </Grid>
          {!fragment?.trailer && (
            <Grid item md={12}>
              <FragmentMeta fragment={fragment} />
            </Grid>
          )}
        </Grid>
      </Container>
      <FragmentIngestDialog
        open={fragmentIngestDialogOpen}
        onClose={() => setFragmentIngestDialogOpen(false)}
      />
      <FragmentClearCache
        open={fragmentClearCacheDialogOpen}
        onClose={() => setFragmentClearCacheDialogOpen(false)}
      />
      <FragmentSocialPost
        open={fragmentSocialPostDialogOpen}
        onClose={() => setFragmentSocialPostDialogOpen(false)}
      />
      <FragmentDeleteDialog
        open={fragmentDeleteDialogOpen}
        onClose={() => setDeleteFragmentDialogOpen(false)}
      />
      <FragmentArchiveDialog
        open={fragmentAchriveDialogOpen}
        onClose={() => setFragmentAchriveDialogOpen(false)}
      />
    </StyledComponent>
  );
};

export default memo(FragmentDetail);
