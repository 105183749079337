import { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormContext } from "react-hook-form";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// Component
import AppBar from "@material-ui/core/AppBar";
import Alert from "@material-ui/lab/Alert";
import Tooltip from "@material-ui/core/Tooltip";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/Button";

import AssetEditForm from "./AssetEditForm";
import StyledDialog from "./style";
import {
  archiveAsset,
  deleteAsset,
  saveAsset,
  unArchiveAsset,
} from "../services";
import { findByIdSuccess } from "../Asset.actions";
import { FORM_MODES } from "./constants";
import { Grid } from "@material-ui/core";
import { loadFragment } from "features/Fragment/reducer";
import { getCurrentFragment } from "features/Fragment/selectors";

const AssetEdit = ({
  open,
  onClose,
  asset,
  type,
  autoFocusField,
  mode = FORM_MODES.new,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const channel = useSelector((state) => state.channel) || {};
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedFragment = useSelector(getCurrentFragment);

  const validationSchema = yup.object({
    type: yup.mixed(),
    name: yup.string().required(`${t("labels.FIELD_IS_REQUIRED")}`),
    identifier: yup.string().required(`${t("labels.FIELD_IS_REQUIRED")}`),
    licenses: yup.array().min(1),
    contracts: yup.array().min(1),
  });

  const defaultValues = {
    type: type || "video",
    quality: null,
    asset: null,
    season: null,
    licenses: [],
    contracts: [],
    index: 0,
    quality_check: true,
    indexable: true,
  };

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    setValue,
    reset,
    formState,
    watch,
  } = useForm({
    defaultValues,
    validationSchema,
  });

  const onSubmit = (data) => {
    const assetData = {
      ...asset,
      ...data,
    };
    delete assetData.asset;

    if (assetData.type === "season") {
      assetData.parent = data.asset;
    }

    if (assetData.type === "episode") {
      assetData.parent = data.season;
    }

    const rewriteCurrentTypeInUrl = (response) => {
      const typeChanged = asset.type !== data.type;
      // Replace video/series url if type has changed
      if (!typeChanged) return;

      history.replace(
        `/channel/${channel.selected}/${
          data.type === "video" ? "video" : "series"
        }/${response.data.id}`
      );
    };

    const redirectToNewAsset = (response) => {
      // Redirect to new asset
      history.push(
        `/channel/${channel.selected}/${
          response.data.type === "video" ? "video" : "series"
        }/${response.data.id}`
      );
    };

    setError(null);
    setLoading(true);

    saveAsset(assetData)
      .then((response) => {
        dispatch(findByIdSuccess(response.data));
        if (asset) {
          onClose();
          rewriteCurrentTypeInUrl(response);
        } else {
          redirectToNewAsset(response);
        }
      })
      .catch((serverError) => {
        setError(serverError);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!asset) return;
    const newAsset = { ...asset };

    const values = {
      ...newAsset,
      contracts: [...(newAsset?.contracts || [])],
      licenses: [...(newAsset?.licenses || [])],
      ...(newAsset.parent &&
        newAsset.type === "season" && { asset: newAsset.parent }),
      ...(newAsset.parent &&
        newAsset.type === "episode" && {
          asset: newAsset.series,
          season: newAsset.parent,
        }),
    };

    reset(values);
  }, [asset, reset]);

  // Invoke formState dirty values so that the proxy is
  // trigger/activated. Otherwise formState.isDirty doesn't work
  useEffect(() => {
    if (!formState) return;
    formState.dirtyFields.values();
  }, [formState]);

  const archive = async () => {
    const response = await archiveAsset(asset.id);
    dispatch(findByIdSuccess(response.data));
    if (selectedFragment) {
      dispatch(loadFragment({ fragmentId: selectedFragment.data.id }));
    }
  };

  const unArchive = async () => {
    const response = await unArchiveAsset(asset.id);
    dispatch(findByIdSuccess(response.data));
    if (selectedFragment) {
      dispatch(loadFragment({ fragmentId: selectedFragment.data.id }));
    }
  };

  const deleteAction = async () => {
    const type = asset.type === "video" ? "video" : "series";
    await deleteAsset(asset.id);
    history.push(`/channel/${channel.selected}/${type}`);
  };

  return (
    <StyledDialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <AppBar position="sticky">
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
            {mode === FORM_MODES.edit
              ? `${t("labels.CHANGE_ASSET")}`
              : `${t("labels.ADD_ASSET")}`}
          </Typography>
          <Tooltip title={t("labels.CLOSE")}>
            <IconButton color="inherit" aria-label="Close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <FormContext
        register={register}
        errors={errors}
        control={control}
        watch={watch}
        getValues={getValues}
        setValue={setValue}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <AssetEditForm mode={mode} autoFocusField={autoFocusField} />
          {error && <Alert severity="error">{t("error.FORM")}</Alert>}
          <DialogActions className="dialogActions">
            <Grid container direction="row" alignItems="center">
              <Grid item xs></Grid>
              <Grid item>
                <Button
                  type="submit"
                  color="primary"
                  trackName="asset.edit.save"
                  trackDetails={{
                    assetId: asset?.id,
                    assetName: asset?.name,
                  }}
                  loading={loading}
                  disabled={!formState.dirty}
                >
                  {mode === FORM_MODES.edit
                    ? `${t("buttons.CHANGE")}`
                    : `${t("buttons.ADD")}`}
                </Button>
              </Grid>
              <Grid container justifyContent="flex-end" spacing={1}>
                {mode === FORM_MODES.edit && (
                  <>
                    {" "}
                    <Grid item>
                      {asset?.archived ? (
                        <Button
                          color="primary"
                          aria-label={t("buttons.UNARCHIVE")}
                          trackName="asset.edit.unarchive"
                          trackDetails={{
                            assetId: asset?.id,
                            assetName: asset?.name,
                          }}
                          onClick={unArchive}
                          startIcon={<UnarchiveIcon />}
                        >
                          {t("buttons.UNARCHIVE")}
                        </Button>
                      ) : (
                        <Button
                          dialog
                          dialogTitle={t("buttons.ARCHIVE")}
                          dialogDescription={t(
                            "text.ARCHIVE_FRAGMENT_CONFIRMATION",
                            {
                              fragment: asset?.name,
                            }
                          )}
                          dialogButtonText={t("buttons.ARCHIVE")}
                          dialogButtonColor="secondary"
                          dialogCallback={archive}
                          color="primary"
                          aria-label={t("buttons.ARCHIVE")}
                          trackName="asset.edit.archive"
                          trackDetails={{
                            assetId: asset?.id,
                            assetName: asset?.name,
                          }}
                          startIcon={<ArchiveIcon />}
                        >
                          {t("buttons.ARCHIVE")}
                        </Button>
                      )}
                    </Grid>
                    <Grid item>
                      <Button
                        dialog
                        dialogTitle={t("buttons.DELETE_PERMANENTLY")}
                        dialogDescription={t(
                          "text.DELETE_FRAGMENT_CONFIRMATION",
                          {
                            fragment: asset?.name,
                          }
                        )}
                        dialogButtonText={t("buttons.DELETE")}
                        dialogButtonColor="secondary"
                        dialogCallback={deleteAction}
                        color="secondary"
                        aria-label={t("buttons.DELETE")}
                        trackName="asset.edit.delete"
                        trackDetails={{
                          assetId: asset?.id,
                          assetName: asset?.name,
                        }}
                        startIcon={<DeleteForeverIcon />}
                      >
                        {t("buttons.DELETE")}
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </FormContext>
    </StyledDialog>
  );
};

export default memo(AssetEdit);
